#__next {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#__next::-webkit-scrollbar {
  display: none;
}
